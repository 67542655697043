import { PayoutMethodType } from "@trolley/common-frontend";
import React, { useEffect, useState } from "react";

import {
  Button,
  Container,
  Divider,
  Grid,
  Icon,
  LinkButton,
  List,
  Loader,
  Modal,
  Text,
  TitleBar,
  Tooltip,
} from "components";
import { RecipientAccountDisplay } from "features";
import { PATHS } from "pages/App/routes";
import { RecipientAccount, deletePayoutMethod } from "store/actions/recipient";
import { useMerchant } from "store/hooks/merchant";
import { useRecipient } from "store/hooks/recipient";
import { useIntl } from "utils/context";
import { useNewProductsLogic, useProfileComplete } from "utils/hooks";

export const integrationToFeature = {
  [PayoutMethodType.BITCOIN]: "payoutBitcoin",
  [PayoutMethodType.INTERAC]: "payoutInterac",
  [PayoutMethodType.CHECK]: "payoutCheck",
  [PayoutMethodType.VENMO]: "payoutVenmo",
};

export default function RecipientAccounts() {
  const merchant = useMerchant();
  const recipient = useRecipient();
  const { confirm } = Modal.useModal();
  const [deleting, setDeleting] = useState(false);
  const applyNewProductsLogic = useNewProductsLogic();
  const isProfileComplete = useProfileComplete();
  const [payoutPath, setPayoutPath] = useState<PATHS.INFO | PATHS.PAYOUT>(PATHS.INFO);

  useEffect(() => {
    if (applyNewProductsLogic) {
      setPayoutPath(isProfileComplete ? PATHS.PAYOUT : PATHS.INFO);
    }
  }, [applyNewProductsLogic, isProfileComplete]);

  const { formatMessage } = useIntl();

  if (!recipient || !merchant) {
    return <Loader />;
  }

  const enabledAccounts = !recipient.accounts
    ? []
    : recipient.accounts
        .filter((account) =>
          merchant.enabledPayoutMethods.find((pm) => {
            const integration = pm.integration || "";

            if (integrationToFeature[integration] && !merchant.features?.[integrationToFeature[integration]]) {
              return false;
            }

            return account.type && integration === account.type;
          }),
        )
        .sort((a, b) => (a.primary ? -1 : b.primary ? 1 : 0));

  async function onConfirmDelete(id: string) {
    if (id && recipient && !deleting) {
      setDeleting(true);
      await deletePayoutMethod(recipient.id, id);
      setDeleting(false);
    }
  }

  function renderPayoutMethod(account: RecipientAccount) {
    return (
      <Grid justify="space-between" alignItems="middle" wrap={false} padding="none">
        <Grid.Item flex={1}>
          <RecipientAccountDisplay account={account} />
        </Grid.Item>
        <Grid.Item>
          <Grid wrap={false} alignItems="middle" padding="none">
            <Grid.Item style={{ paddingRight: "6px" }}>
              <Tooltip title={formatMessage({ id: "common.edit" })}>
                <LinkButton
                  icon="pencil"
                  shape="circle"
                  to={`${PATHS.PAYOUT}/${account.id}`}
                  aria-label={formatMessage({ id: "common.edit" })}
                />
              </Tooltip>
            </Grid.Item>
            <Grid.Item>
              <Tooltip title={formatMessage({ id: "common.delete" })}>
                <Button
                  icon="trash-alt"
                  shape="circle"
                  onClick={() => {
                    confirm({
                      title: formatMessage({
                        id: "containers.accountSummary.deletingPayoutMethod.1",
                      }),
                      okText: formatMessage({ id: "common.delete" }),
                      onOk: async () => {
                        await onConfirmDelete(account.id);
                      },
                      okType: "danger",
                      content: formatMessage({
                        id: "containers.accountSummary.deletingPayoutMethod.2",
                      }),
                    });
                  }}
                  type="danger"
                  aria-label={formatMessage({ id: "common.delete" })}
                />
              </Tooltip>
            </Grid.Item>
          </Grid>
        </Grid.Item>
      </Grid>
    );
  }

  if (enabledAccounts.length > 0) {
    return (
      <Container>
        <TitleBar level={2}>
          {formatMessage({
            id: "containers.accountSummary.titles.payoutMethods",
          })}
        </TitleBar>
        <List<RecipientAccount>
          items={!merchant || !recipient || !recipient.accounts ? [] : enabledAccounts}
          renderItem={renderPayoutMethod}
          showMoreText={formatMessage({
            id: "containers.accountSummary.buttons.seeMorePayoutMethods",
          })}
          showLessText={formatMessage({
            id: "containers.accountSummary.buttons.seeLessPayoutMethods",
          })}
          defaultRecordCount={3}
        />
        <Divider transparent size="small" />
        <LinkButton icon="plus" type="primary" to={payoutPath} placement="right">
          {formatMessage({
            id: "containers.accountSummary.buttons.addPayoutMethod",
          })}
        </LinkButton>
      </Container>
    );
  }

  if (merchant.enabledPayoutMethods.length === 0) {
    return (
      <div style={{ textAlign: "center", padding: "80px 0" }}>
        <Text padded>
          <Icon size="xlarge" theme="light" type="money-bill-alt" />
        </Text>
        <Text strong padded>
          {formatMessage({
            id: "containers.accountSummary.noPayoutMethodEnabled.1",
          })}
        </Text>
        <Text padded>
          {formatMessage({
            id: "containers.accountSummary.noPayoutMethodEnabled.2",
          })}
        </Text>
        <Divider transparent />
        <LinkButton size="large" type="primary" to={{ pathname: PATHS.INFO, state: { saveAndReturn: true } }}>
          {formatMessage({
            id: "containers.accountSummary.buttons.editInformation",
          })}
        </LinkButton>
      </div>
    );
  }

  return (
    <div style={{ textAlign: "center", margin: "60px 0" }}>
      <Text padded>
        <Icon size="xlarge" theme="light" type="money-bill-alt" />
      </Text>
      <Text strong padded>
        {formatMessage({
          id: "containers.accountSummary.noPayoutMethodSetup.1",
        })}
      </Text>
      <Text>
        {formatMessage({
          id: "containers.accountSummary.noPayoutMethodSetup.2",
        })}
      </Text>
      <Divider transparent />
      <LinkButton size="large" icon="plus" type="primary" to={payoutPath}>
        {formatMessage({
          id: "containers.accountSummary.buttons.addPayoutMethod",
        })}
      </LinkButton>
      <Divider transparent size="large" />
    </div>
  );
}
