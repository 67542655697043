import { CountryCode, TaxDeliveryType, TaxFormType, UsUploadTaxFormType } from "@trolley/common-frontend";
import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";

import {
  BackButton,
  Button,
  Container,
  Divider,
  FileDownload,
  Footer,
  Form,
  GreyBox,
  Grid,
  LinkButton,
  Loader,
  Modal,
  Text,
  TitleBar,
  UploadButton,
} from "components";
import { useForm } from "components/Form";
import { UploadFile } from "components/UploadButton";
import { PATHS } from "pages/App/routes";
import { useHistory, useParams } from "react-router-dom";
import { notifyError } from "store/actions/notifications";
import {
  TaxForm,
  UsUpload,
  getTaxFormLabel,
  signAndSubmitTaxForm,
  updateTaxForm,
  uploadTaxDocument,
} from "store/actions/taxForms";
import { useMerchant } from "store/hooks/merchant";
import { useRecipient } from "store/hooks/recipient";
import { useTaxForm, useTaxForms } from "store/hooks/taxForms";
import { BaseStatus } from "store/reducers/standardReducer";
import { ActionType, useIntl, useStepperEffect } from "utils/context";
import { handleFormErrors, omitMaskedValues } from "utils/helpers";
import OtherUploadForm from "./OtherUploadForm";
import W9UploadForm from "./W9UploadForm";

type UploadKind = {
  label: string;
  value: TaxFormType | UsUploadTaxFormType;
  url: string;
  instructions: string;
  revisions: string[];
};

export function isUsUpload(data: TaxForm["data"], kind: TaxForm["kind"]): data is UsUpload {
  return kind === TaxFormType.US_UPLOAD;
}

const uploadKinds: UploadKind[] = [
  {
    value: TaxFormType.W9,
    url: "https://www.irs.gov/pub/irs-pdf/fw9.pdf",
    instructions: "https://www.irs.gov/pub/irs-pdf/iw9.pdf",
    revisions: [
      "2024-03",
      "2018-10",
      "2017-11",
      "2014-12",
      "2013-08",
      "2011-12",
      "2007-10",
      "2005-11",
      "2004-10",
      "2003-01",
      "2002-01",
      "2000-12",
      "1999-11",
      "1996-12",
      "1994-03",
      "1993-12",
      "1990-04",
      "1988-12",
      "1987-12",
      "1984-07",
      "1983-10",
    ],
  },
  {
    value: UsUploadTaxFormType.W8IMY,
    url: "https://www.irs.gov/pub/irs-pdf/fw8imy.pdf",
    instructions: "https://www.irs.gov/pub/irs-pdf/iw8imy.pdf",
    revisions: ["2021-10", "2017-06", "2016-09", "2014-04", "2006-02", "2003-12", "2000-12", "1998-10"],
  },
  {
    value: UsUploadTaxFormType.W8ECI,
    url: "https://www.irs.gov/pub/irs-pdf/fw8eci.pdf",
    instructions: "https://www.irs.gov/pub/irs-pdf/iw8eci.pdf",
    revisions: ["2021-10", "2017-07", "2014-02", "2006-02", "2006-01", "2000-12", "1998-10"],
  },
  {
    value: UsUploadTaxFormType.W8EXP,
    url: "https://www.irs.gov/pub/irs-pdf/fw8exp.pdf",
    instructions: "https://www.irs.gov/pub/irs-pdf/iw8exp.pdf",
    revisions: ["2023-10", "2017-07", "2016-09", "2014-04", "2006-02", "2006-01", "2000-12", "1998-10"],
  },
  {
    value: UsUploadTaxFormType.W4,
    url: "https://www.irs.gov/pub/irs-pdf/fw4.pdf",
    instructions: "https://www.irs.gov/pub/irs-pdf/fw4.pdf",
    revisions: [
      "2020",
      "2019",
      "2018",
      "2017",
      "2016",
      "2015",
      "2014",
      "2013",
      "2012",
      "2011",
      "2010",
      "2009",
      "2008",
      "2007",
      "2006",
      "2005",
      "2004",
      "2003",
      "2002",
      "2001",
      "2000",
      "1999",
      "1998",
      "1997",
      "1996",
      "1995",
      "1994",
      "1993",
      "1992",
      "1990",
    ],
  },
  {
    value: TaxFormType.F8233,
    url: "https://www.irs.gov/pub/irs-pdf/f8233.pdf",
    instructions: "https://www.irs.gov/pub/irs-pdf/i8233.pdf",
    revisions: ["2018-09", "2009-03", "2001-12", "1996-10", "1993-04", "1987-10", "1985-12", "1984-12"],
  },
].map((f) => ({ ...f, label: getTaxFormLabel(f.value) }));

export type FormFields =
  | {
      data: {
        uploadKind: Extract<TaxFormType, TaxFormType.F8233> | UsUploadTaxFormType;
        revision: string;
        firstName: string;
        lastName: string;
        dob: string;
        mailingAddress: string;
        mailingCity: string;
        mailingCountry: CountryCode;
        mailingRegion: string;
        mailingPostalCode: string;
        taxPayerUsId?: string;
        taxPayerForeignId?: string;
        signedAt: string;
      };
    }
  | {
      data: {
        uploadKind: TaxFormType.W9;
        revision: string;
        signedAt: string;
        w9Data: {
          name: string;
          firstName: string;
          lastName: string;
          dbaName?: string;
          address: string;
          city: string;
          country: string;
          state: string;
          zip: string;
          taxType: string;
          idType: string;
          taxId: string;
          subjectToWithholding: boolean;
        };
      };
    };

export default function UploadTaxForm() {
  useStepperEffect(ActionType.TAX_US, 1 / 2);
  const [form] = useForm();
  const { taxId } = useParams<{ taxId: string | undefined }>();
  const history = useHistory<{
    formType?: TaxFormType | UsUploadTaxFormType;
  }>();
  const { data: uploadedForm, status: uploadFormStatus } = useTaxForm(taxId);
  const [file, setFile] = useState<UploadFile>();
  const [loading, setLoading] = useState(false);
  const [showWithholdingWarning, setShowWithholdingWarning] = useState(false);

  const recipient = useRecipient();
  const merchant = useMerchant();
  const { data: taxForms } = useTaxForms();
  const { formatMessage } = useIntl();

  useEffect(() => {
    const update = {
      kind: TaxFormType.US_UPLOAD,
      ...uploadedForm,
      data: {
        uploadKind: history.location.state?.formType,
        ...(uploadedForm?.data as any),
      },
    };
    if (isUsUpload(update.data, update.kind) && update.data.uploadKind === TaxFormType.W9) {
      // default subjectToWithholding to true. we want user to explicitly set to false.
      update.data = {
        ...update.data,
        w9Data: {
          ...update.data.w9Data,
          subjectToWithholding: update.data.w9Data?.subjectToWithholding ?? true,
        },
      };
    }
    form.setFieldsValue(update);
  }, [uploadedForm]);

  async function onUpload(values: FormFields) {
    if (file && !loading) {
      try {
        setLoading(true);
        const newForm = await uploadTaxDocument(file);
        if (newForm) {
          await updateTaxForm(newForm.id, values);

          history.replace(`${history.location.pathname}/${newForm.id}`);
        }
      } catch (error) {
        notifyError(formatMessage({ id: "containers.tax.uploadTaxForm.failed" }));
      }
      setLoading(false);
    }
  }

  async function onSubmit(values: FormFields) {
    if (!loading && recipient) {
      if (!uploadedForm) {
        await onUpload(values);
      } else if (
        values.data.uploadKind !== TaxFormType.W9 &&
        !values.data.taxPayerUsId &&
        !values.data.taxPayerForeignId &&
        !showWithholdingWarning
      ) {
        setShowWithholdingWarning(true);
      } else {
        setLoading(true);
        if (uploadedForm?.id) {
          try {
            await updateTaxForm(uploadedForm.id, omitMaskedValues(values));
            await signAndSubmitTaxForm(
              uploadedForm.id,
              values.data.uploadKind === TaxFormType.W9
                ? values.data.w9Data.name
                : `${values.data.firstName} ${values.data.lastName}`,
            );
            setShowWithholdingWarning(false);

            const isFirstForm = taxForms.records.filter((f) => f.signedAt).length === 0;
            if (recipient.taxDeliveryType === TaxDeliveryType.MAIL || isFirstForm) {
              history.push(PATHS.US_TAX_DELIVERY);
            } else {
              history.push(PATHS.US_TAX_COMPLETE);
            }
          } catch (errors) {
            setShowWithholdingWarning(false);
            handleFormErrors(errors, form);
          }
        }
        setLoading(false);
      }
    }
  }

  return (
    <Form form={form} onFinish={onSubmit} initialValues={uploadedForm}>
      <Loader spinning={loading || uploadFormStatus === BaseStatus.LOADING}>
        <Container>
          <Helmet>
            <title>{formatMessage({ id: "containers.tax.uploadTaxForm.title" })}</title>
          </Helmet>
          <TitleBar>{formatMessage({ id: "containers.tax.uploadTaxForm.title" })}</TitleBar>
          <Grid>
            <Grid.Item xs={24} sm={12}>
              <Form.Field
                name={["data", "uploadKind"]}
                label={formatMessage({
                  id: "containers.tax.uploadTaxForm.formType",
                })}
                rules={[
                  {
                    required: true,
                    message: formatMessage({
                      id: "containers.tax.uploadTaxForm.formTypeRequired",
                    }),
                  },
                ]}
              >
                <Form.Select
                  name="uploadKind"
                  options={[
                    {
                      label: formatMessage({
                        id: "containers.tax.uploadTaxForm.formTypePlaceholder",
                      }),
                      value: "",
                      disabled: true,
                    },
                    ...uploadKinds,
                  ]}
                  onChange={(e) => {
                    const update: any = {
                      data: {
                        taxPayerUsId: undefined,
                        revision: undefined,
                      },
                    };
                    if (update.data && e.target.value === TaxFormType.W9) {
                      update.data.w9Data = {
                        subjectToWithholding: true,
                      };
                      if (
                        uploadedForm &&
                        isUsUpload(uploadedForm.data, uploadedForm.kind) &&
                        uploadedForm.data.uploadKind === TaxFormType.W9
                      ) {
                        const w9Data = uploadedForm.data.w9Data;
                        update.data.w9Data.name =
                          w9Data?.name ||
                          [w9Data?.firstName, w9Data?.lastName].filter((v) => !!v).join(" ") ||
                          undefined;
                      }
                    }
                    form.setFieldsValue(update);
                  }}
                />
              </Form.Field>
            </Grid.Item>
            <Form.Control<TaxForm>
              shouldUpdate={(prev, next) =>
                // shouldUpdate is needed because setFieldsValue does not rerender
                (prev.data as any)?.uploadKind !== (next.data as any)?.uploadKind
              }
            >
              {({ value }) => {
                const uploadKind = value?.data?.uploadKind;
                const uploadKindData = uploadKinds.find((item) => item.value === uploadKind);

                return (
                  <>
                    <Grid.Item xs={24} sm={12}>
                      <Form.Field
                        name={["data", "revision"]}
                        label={formatMessage({
                          id: "containers.tax.uploadTaxForm.revision",
                        })}
                        rules={[
                          {
                            required: true,
                            message: formatMessage({
                              id: "containers.tax.uploadTaxForm.revisionRequired",
                            }),
                          },
                        ]}
                      >
                        <Form.Select
                          key={uploadKind || "kind"} // this is required to refresh UI list of optionsn
                          name="revision"
                          disabled={!uploadKindData?.revisions}
                          options={[
                            {
                              label: formatMessage({
                                id: "containers.tax.uploadTaxForm.revisionPlaceholder",
                              }),
                              value: "",
                              disabled: true,
                            },
                            ...(uploadKindData?.revisions || [])?.map((revision: string) => ({
                              label: revision,
                              value: revision,
                            })),
                          ]}
                        />
                      </Form.Field>
                    </Grid.Item>

                    {!uploadedForm && uploadKindData && (
                      <Grid.Item xs={24}>
                        <Text padded>
                          {formatMessage(
                            {
                              id: "containers.tax.uploadTaxForm.completeManually",
                            },
                            {
                              label: (
                                <Button type="link" href={uploadKindData.url} target="_blank" rel="noopener noreferrer">
                                  {uploadKindData.label}
                                </Button>
                              ),
                              instructions: (
                                <Button
                                  type="link"
                                  href={uploadKindData.instructions}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {formatMessage(
                                    {
                                      id: "containers.tax.uploadTaxForm.irsInstructions",
                                    },
                                    { label: uploadKindData.label },
                                  )}
                                </Button>
                              ),
                            },
                          )}
                        </Text>
                        <Text padded>
                          {formatMessage({
                            id: "containers.tax.uploadTaxForm.onceCompleted",
                          })}
                        </Text>
                        <Divider transparent size="small" />
                      </Grid.Item>
                    )}
                  </>
                );
              }}
            </Form.Control>
          </Grid>
          {merchant?.tax?.collectionType === "us_international" && (
            <>
              <Text type="secondary" size="small" padded>
                {formatMessage(
                  { id: "containers.tax.uploadTaxForm.note" },
                  {
                    link1: (content) => (
                      <LinkButton
                        type="link"
                        to={{
                          pathname: PATHS.US_TAX_NEW,
                          state: { formType: TaxFormType.W8BEN },
                        }}
                      >
                        {content}
                      </LinkButton>
                    ),
                    link2: (content) => (
                      <LinkButton
                        type="link"
                        to={{
                          pathname: PATHS.US_TAX_NEW,
                          state: { formType: TaxFormType.W8BENE },
                        }}
                      >
                        {content}
                      </LinkButton>
                    ),
                  },
                )}
              </Text>
            </>
          )}

          <Divider transparent size="small" />

          {!uploadedForm ? (
            <UploadButton onSelectFile={setFile} />
          ) : (
            <>
              <FileDownload
                url={`/v1/recipients/${recipient?.id}/tax/${uploadedForm.id}/download`}
                fileName={`PR_UploadedForm_${uploadedForm.id}`}
                icon="file-alt"
                size="middle"
              >
                {formatMessage({
                  id: "containers.tax.uploadTaxForm.uploadFile",
                })}
              </FileDownload>
              <Divider size="large" />
              <TitleBar level={2}>
                {formatMessage({
                  id: "containers.tax.uploadTaxForm.taxInformation",
                })}
              </TitleBar>
              <GreyBox margin="large">
                {formatMessage({
                  id: "containers.tax.uploadTaxForm.exactlyMatch",
                })}
              </GreyBox>
              <Form.Control
                shouldUpdate={(prev: TaxForm, next: TaxForm) =>
                  // shouldUpdate is needed because setFieldsValue does not rerender
                  (prev.data as any)?.uploadKind !== (next.data as any)?.uploadKind
                }
              >
                {({ value }) => {
                  const uploadKind = value?.data?.uploadKind;
                  if (!uploadKind) {
                    return null;
                  }

                  return uploadKind === TaxFormType.W9 ? <W9UploadForm /> : <OtherUploadForm />;
                }}
              </Form.Control>
            </>
          )}
          <Footer
            main={
              <Button
                type="primary"
                size="large"
                loading={loading || uploadFormStatus === BaseStatus.LOADING}
                disabled={!uploadedForm && !file}
                htmlType="submit"
              >
                {uploadedForm
                  ? formatMessage({
                      id: "containers.tax.uploadTaxForm.saveAndSubmit",
                    })
                  : formatMessage({
                      id: "containers.tax.uploadTaxForm.uploadForm",
                    })}
              </Button>
            }
            extra={<BackButton />}
          />
        </Container>
      </Loader>

      <Modal
        open={!!showWithholdingWarning}
        onCancel={() => {
          setShowWithholdingWarning(false);
        }}
        confirmLoading={loading}
        onOk={() => {
          form.submit();
        }}
        okText={formatMessage({ id: "common.continue" })}
        title={formatMessage({ id: "containers.tax.uploadTaxForm.noTaxId" })}
      >
        <GreyBox>
          <b>{formatMessage({ id: "containers.tax.subjectToWithholding" })}</b>
        </GreyBox>
      </Modal>
    </Form>
  );
}
