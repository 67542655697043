import React from "react";

import { useNewStyle } from "pages/App";
import Payments from "pages/Payments";
import OpenTickets from "pages/Tickets";
import { useIframeConfig } from "store/hooks/config";
import { useMerchant } from "store/hooks/merchant";
import { useRecipient } from "store/hooks/recipient";
import BrazilWarnings from "./BrazilWarnings";
import DAC7EndOfYearStatements from "./DAC7EndOfYearStatements";
import EndOfYearForms from "./EndOfYearForms";
import Trust from "../Trust";
import Profile from "./profile";
import InactiveReasons from "./InactiveReasons";
import RecipientAccounts from "./RecipientAccounts";
import RecipientAccounts2 from "./RecipientAccounts2";
import TaxForms from "./TaxForms";
import TaxProfiles from "./TaxProfiles";
import { PRODUCT_MODULES, useIntl, useProducts } from "utils/context";
import { showProduct } from "utils/helpers";
import ReadyStepper from "../App/ReadyStepper";
import Helmet from "react-helmet";
import { useNewProductsLogic } from "utils/hooks";

export default function AccountSummary() {
  const recipient = useRecipient();
  const merchant = useMerchant();
  const config = useIframeConfig();
  const newStyle = useNewStyle();
  const intl = useIntl();
  const { formatMessage } = intl;
  const { queryProducts, entitledQueriedProducts } = useProducts();
  const showTaxProduct = showProduct({ product: PRODUCT_MODULES.TAX, queryProducts, entitledQueriedProducts });
  const showPayProduct = showProduct({ product: PRODUCT_MODULES.PAY, queryProducts, entitledQueriedProducts });
  const showTrustProduct = showProduct({ product: PRODUCT_MODULES.TRUST, queryProducts, entitledQueriedProducts });
  const showPayoutMethods = !!config.showPayoutMethods;
  const showIdVerification =
    (!!merchant?.features?.idv && merchant?.trust?.requireIDV) ||
    (!!merchant?.features?.dsa && merchant?.trust?.requireDSA);
  const applyNewProductsLogic = useNewProductsLogic();

  if (!recipient || !merchant) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>
          {formatMessage({
            id: "containers.accountSummary.pageName",
          })}
        </title>
      </Helmet>
      {newStyle && <ReadyStepper />}
      {((showTrustProduct && showIdVerification) || (applyNewProductsLogic && (showPayProduct || showTaxProduct))) && (
        <Profile />
      )}

      {showPayProduct && showPayoutMethods && <InactiveReasons recipient={recipient} showLink />}

      {showPayProduct && showPayoutMethods && <BrazilWarnings recipient={recipient} />}

      <OpenTickets />

      {showPayProduct && config.showPayments && recipient.accounts.length > 0 && <Payments listType="recent" />}

      {showPayProduct && showPayoutMethods && (newStyle ? <RecipientAccounts2 /> : <RecipientAccounts />)}

      {showTrustProduct && showIdVerification && <Trust />}

      {showTaxProduct &&
        !!merchant.features?.tax &&
        merchant.tax?.enabled &&
        config.usTax && ( // Display US Tax Collection
          <>
            <TaxForms />
            <EndOfYearForms />
          </>
        )}
      {showTaxProduct && !!merchant.features?.euTax && merchant.euTax?.enabled && (
        <>
          <TaxProfiles />
          <DAC7EndOfYearStatements />
        </>
      )}
    </>
  );
}
