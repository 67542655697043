/* istanbul ignore file */
import { useState, useEffect } from "react";
import { useMerchant } from "store/hooks/merchant";

// TODO: When this logic is enabled to all recipients this hook will be removed
export default function useNewProductsLogic() {
  const merchant = useMerchant();
  const [newProductsLogic, setNewProductsLogic] = useState(false);

  useEffect(() => {
    setNewProductsLogic(() => !!merchant?.features?.widgetProfileLoadingBeta || !!merchant?.sandbox);
  }, [merchant]);

  return newProductsLogic;
}
